import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages';
import PrivacyPolicy from './pages/PrivacyPolicy';

function App() {
	return (
		<Router>
			<Switch>
				<Route path="/" component={Home} exact />
				<Route path="/privacy-policy" component={PrivacyPolicy} exact />
			</Switch>
		</Router>
	);
}

export default App;
