import React from 'react';
import styled from 'styled-components';
import background from '../assets/background.jpg';
import tCreateLogo from '../assets/t-create-logo.png';
import tCreateLogo2x from '../assets/t-create-logo@2x.png';

const SiteContainer = styled.div`
	background: #0c0c0c url('${background}') no-repeat top center;
	background-attachment: fixed;
	background-size: cover;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-top: 30px;
`;
const PrivacyPolicyContainer = styled.div`
	width: 100%;
	max-width: 1500px !important;
	padding: 0 24px;
`;

const CustomParagraph = styled.p`
	margin-bottom: 20px;
`;

const CustomList = styled.ul`
	padding-left: 30px;
`;

const CustomTable = styled.table`
	border-collapse: collapse;
	td,
	th {
		border: 1px solid #fff;
		padding: 5px;
	}
	th {
		font-weight: bold;
	}
`;

const PrivacyPolicy = () => {
	return (
		<SiteContainer>
			<PrivacyPolicyContainer>
				<CustomParagraph>
					<img src={tCreateLogo} srcSet={`${tCreateLogo2x} 2x`} alt="" />
				</CustomParagraph>

				<CustomParagraph style={{ textAlign: 'center', fontWeight: 'bold' }}>
					Polityka prywatności i przetwarzania danych osobowych T-Create sp. z o.o. z siedzibą w 20-150 Lublin, Bursaki 18 lok. 17, NIP 712-337-84-84, dalej T-Create lub Spółka
				</CustomParagraph>
				<CustomParagraph>
					Przestrzeganie określonych w niniejszym dokumencie zasad polityki prywatności i przetwarzania danych osobowych przez T-Create Sp. z o.o. chroni powierzone Spółce dane osobowe, zapewniając
					jednocześnie realizację obowiązków wynikających z przepisów Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w
					związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE oraz przepisów przewidzianych w polskiej ustawie o ochronie danych
					osobowych, aktów wykonawczych do tej ustawy oraz ustawie o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz. U. z 2002r., nr 144, poz. 1204, ze zm.)
				</CustomParagraph>
				<CustomParagraph>
					Zgodnie z art. 13 ust. 1 i ust. 2 RODO informacje dotyczące przetwarzania danych osobowych w T-Create przekazywane są do ogólnie dostępnej wiadomości poprzez publikację niniejszego dokumentu
					w sieci Internet pod adresem www.tcreate.pl/rodo/
				</CustomParagraph>
				<CustomParagraph>
					Definicje:
					<CustomList>
						<li>
							<strong>RODO</strong> - Rozporządzenie Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 roku w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych
							osobowych i w sprawie swobodnego przepływu takich danych
						</li>
						<li>
							<strong>Administrator danych osobowych („Administrator”)</strong> - oznacza osobę fizyczną lub prawną, organ publiczny, jednostkę lub inny podmiot, który samodzielnie lub wspólnie z
							innymi ustala cele i sposoby przetwarzania danych osobowych;
						</li>
						<li>
							<strong>Podmiot przetwarzający („Procesor”)</strong> przetwarza dane Osoby fizycznej na zlecenie Administratora. Zasady przetwarzania są regulowane przepisami prawa oraz umowy zawartej
							pomiędzy Podmiotem przetwarzającym a Administratorem;
						</li>
						<li>
							<strong>Dane osobowe</strong> oznaczają informacje o zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej;
						</li>
						<li>
							<strong>Zanonimizowane dane</strong> to dane w których usunięto informacje umożliwiające identyfikację osoby fizycznej;
						</li>
						<li>
							<strong>Przetwarzanie danych</strong> oznacza każdą operację lub zestaw operacji wykonywanych na danych osobowych lub zestawach danych osobowych w sposób zautomatyzowany lub
							niezautomatyzowany, w szczególności: zbieranie, utrwalanie, organizowanie, porządkowanie, przechowywanie, adaptowanie, modyfikowanie, pobieranie, przeglądanie, wykorzystywanie,
							przesłanie, rozpowszechnianie lub innego rodzaju udostępnianie, dopasowywanie, łączenie, ograniczanie, usuwanie lub niszczenie;
						</li>
						<li>
							<strong>Akcje promocyjne</strong> to wszelkiego rodzaju konkursy, promocje, programy lojalnościowe, projekty grywalizacyjne, loterie i inne działania marketingowe lub programistyczne
							realizowane przez T-Create na zlecenie firm trzecich i na podstawie umów z nimi zawartych w ramach prowadzonej przez T-Create działalności gospodarczej;
						</li>
						<li>
							<strong>Polityka Prywatności</strong> to zbiór zasad i reguł przetwarzania danych ujęty w niniejszym dokumencie i jego aktualizacjach;
						</li>
						<li>
							<strong>Rejestr czynności RODO</strong> to wewnętrzny dokument w którym rejestrujemy istotne operacje przetwarzania danych w tym wszelkie przypadki naruszenia bezpieczeństwa
							przetwarzanych danych osobowych.
						</li>
					</CustomList>
				</CustomParagraph>
				<CustomParagraph style={{ textAlign: 'center', fontWeight: 'bold' }}>
					§ 1<br />
					Główne zasady przetwarzania danych w T-Create
				</CustomParagraph>

				<CustomParagraph>
					<CustomList>
						<li>Dane osobowe przetwarzane są zgodnie z prawem, zawartymi przez T-Create umowami i prawnie uzasadnionym interesem Spółki;</li>
						<li>Dane osobowe przetwarzane są rzetelnie i w sposób przejrzysty dla osoby, której dane dotyczą, z poszanowaniem prawa do prywatności;</li>
						<li>
							Dane osobowe przetwarzane są wyłącznie w konkretnych, wyraźnych i prawnie uzasadnionych celach, w zakresie ograniczonym do minimum danych niezbędnego do osiągnięcia tych celów w sposób
							skuteczny i efektywny oraz przez okres nie dłuższy niż jest to niezbędne do realizacji tych celów;
						</li>
						<li>
							T-Create umożliwia osobom, których dane przetwarza, wgląd w te dane, możliwość ich aktualizacji, przenoszenia, ograniczenia przetwarzania oraz zgłoszenia sprzeciwu wobec przetwarzania
							danych osobowych, w tym żądania ich usunięcia o ile nie stoi to w sprzeczności z prawnie uzasadnionym interesem Spółki zgodnie z przepisami RODO;
						</li>

						<li>
							Dane osobowe przetwarzane są w sposób zapewniający odpowiednie bezpieczeństwo danych osobowych, w tym ochronę przed niedozwolonym lub niezgodnym z prawem przetwarzaniem oraz przypadkową
							utratą, zniszczeniem lub uszkodzeniem.
						</li>
					</CustomList>
				</CustomParagraph>
				<CustomParagraph style={{ textAlign: 'center', fontWeight: 'bold' }}>
					§ 2<br />
					Zakres, cele i sposoby przetwarzania danych osobowych przez T-Create
				</CustomParagraph>
				<CustomParagraph>
					T-Create występuje w procesie przetwarzania danych zarówno w charakterze Administratora jak i Procesora W T-Create pozyskujemy i przetwarzamy dane osobowe dla potrzeb realizacji kilku celów.
					Ich opis oraz zakres, cel i czas przetwarzania opisany jest w poniższej tabeli.
					<CustomTable>
						<thead>
							<tr>
								<th>Cel przetwarzania</th>
								<th>Opis przetwarzania</th>
								<th>Czas przetwarzania</th>
								<th>Rodzaj danych</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Realizacja umowy lub zamówienia kontrahenta oraz potwierdzenie wykonania wynikających z nich zobowiązań, dochodzenie roszczeń, ochrona przed roszczeniami.</td>
								<td>Przetwarzamy dane osobowe, które zostały nam przekazane w związku z realizowaną umową lub zamówieniem</td>
								<td>Dane osobowe przetwarzane są przez okres wykonywania zobowiązań oraz okres przedawnienia roszczeń wynikający z przepisów.</td>
								<td>Imię i nazwisko, telefon, adres email, adresy komunikatorów internetowych takich jak Skype, WhatsUp, Messenger.</td>
							</tr>
							<tr>
								<td>Przechowywanie dokumentacji zgodnie z obowiązującymi przepisami prawa.</td>
								<td>Przetwarzamy dane osobowe, które zostały nam przekazane w związku z realizowaną umową lub zamówieniem. </td>
								<td>
									Okres przetwarzania wynika z przepisów prawa oraz prawnie uzasadnionego interesu T-Create, w tym prawo do korzystania z dokumentacji poprzednio wykonanych projektów jako bazy w
									oparciu o którą tworzymy nowe rozwiązania (dotyczy w szczególności korespondencji w ramach realizowanego projektu).
								</td>
								<td>Dane umieszczone w fakturach, korektach, zamówieniach, umowach oraz korespondencji związanej z realizacją danego zadania.</td>
							</tr>
							<tr>
								<td>Odpowiedź na pytanie złożone za pośrednictwem strony internetowej, emaila lub innej formy kontaktu udostępnionej przez T-Create.</td>
								<td>
									W momencie gdy otrzymujemy dane osobowe od osoby wypełniającej formularz internetowy, piszącej do nas wiadomość email lub kontaktującej się w inny sposób rozpoczynamy przetwarzanie
									udostępnionych w efekcie danych osobowych.
								</td>
								<td>Dane osobowe przetwarzane są do momentu wniesienia przez Ciebie sprzeciwu lub wycofania zgody na ich przetwarzanie.</td>
								<td>Dane przekazane nam za pośrednictwem formularza lub innej formy kontaktu, które są istotne dla możliwości udzielenia odpowiedzi.</td>
							</tr>
							<tr>
								<td>Realizacja prawnie uzasadnionego interesu polegającego na sprzedaży i marketingu własnych usług.</td>
								<td>
									Możemy kontaktować się z osobami fizycznymi aby uzyskać ich zgodę na przedstawienie naszej oferty. Dane osobowe wykorzystane w celu nawiązania kontaktu biznesowego pozyskiwane są
									przez nas wyłącznie poprzez ogólnodostępne informacje zamieszczane na stronach internetowych lub w rezultacie osobistych spotkań biznesowych na których takie dane mogą zostać
									przekazane w rezultacie polecenia przez osoby trzecie.
								</td>
								<td>Dane osobowe przetwarzane są do momentu wniesienia przez Ciebie sprzeciwu.</td>
								<td>Imię i nazwisko, telefon, adres email, adresy komunikatorów internetowych takich jak Skype, WhatsUp, Messenger</td>
							</tr>
							<tr>
								<td>
									Realizacja praw i obowiązków wynikających z roli pracodawcy w stosunku do osób będących pracownikami Spółki lub osobami świadczącymi na rzecz T-Create usługi na postawie umów cywilno
									- prawnych.
								</td>
								<td>
									T-Create przetwarza dane osobowe zawarte w umowach oraz dane osobowe przekazane przez pracowników w związku z prawidłową realizacją umów pracowniczych, w tym dane niezbędne do
									prawidłowych rozliczeń podatków oraz odprowadzania składek na ubezpieczenie społeczne.
								</td>
								<td>Okres przetwarzania wynika z przepisów prawa oraz prawnie uzasadnionego interesu T-Create.</td>
								<td>Imię, nazwisko, PESEL, adres zamieszkania, numer konta bankowego oraz dane kontaktowe takie jak adres email, adresy komunikatorów internetowych np. Skype, WhatsUp, Messenger.</td>
							</tr>
							<tr>
								<td>
									Realizacja i obsługa konkursów, promocji, programów lojalnościowych, projektów grywalizacyjnych lub loterii (zwanych dalej Akcjami Promocyjnymi) w których T-Create pełni rolę
									Organizatora lub Procesora działając na zlecenie podmiotów trzecich w ramach zawartych umów.{' '}
								</td>
								<td>
									T-Create realizując i obsługując akcje promocyjne pozyskuje dane osobowe uczestników tych akcji na 2 sposoby: a) otrzymując dane osobowe bezpośrednio od osób, których dane dotyczą
									poprzez formularze elektroniczne lub/i występujące w formie drukowanej w związku z ich przystąpieniem do danej akcji;
									<br />
									<br />
									b) otrzymując dane osobowe od Administratora tych danych będącego jednocześnie zleceniodawcą danej akcji - występując w charakterze Procesora i przetwarzając dane w sposób i zakresie
									wynikającej z zawartej z Administratorem umowy.{' '}
								</td>
								<td>
									Dane przetwarzane są przez czas określony w regulaminie daje akcji promocyjnej o ile dłuższy okres przetwarzania nie wynika z przepisów prawa lub prawnie uzasadnionego interesu
									T-Create.
								</td>
								<td>
									Z uwagi na specyfikę i indywidualny charakter organizowanych akcji promocyjnych zakres przetwarzanych danych jest wskazany szczegółowo w zasadach konkursu, promocji, programu
									lojalnościowego lub loterii. Szczegółowe informacje znajdują się w regulaminach tych akcji. Zakres przetwarzanych danych może być szerszy w przypadku osób, które zostają laureatami
									konkursów - co wynika z obowiązków podatkowych lub innych regulacji przewidzianych prawem bądź regulaminem danej akcji promocyjnej.{' '}
								</td>
							</tr>
							<tr>
								<td>
									Optymalizacja tworzonych przez spółkę narzędzi informatycznych takich jak aplikacje mobilne, strony internetowe, bazy danych oraz monitorowanie ich błędów, skuteczności,
									popularności, efektywności, sprawności działania i innych kluczowych cech i parametrów.
								</td>
								<td>
									T-Create informując użytkowników rozwiązań informatycznych może przetwarzać dane związane z aktywnością użytkowników rozumianą jako działania podejmowane przez użytkowników narzędzi
									informatycznych wykonujących je bez logowania (dane zanonimizowane) oraz po zalogowaniu (dane osobowe).
								</td>
								<td>Okres przetwarzania wynika z przepisów prawa oraz prawnie uzasadnionego interesu T-Create.</td>
								<td>
									Z uwagi na specyfikę tworzonych przez spółkę narzędzi informatycznych zakres monitorowanych aktywności użytkownika może być różny. Informacje te są zatem udostępniane użytkownikom
									konkretnych serwisów za pośrednictwem regulaminu danej usługi.{' '}
								</td>
							</tr>
						</tbody>
					</CustomTable>
				</CustomParagraph>

				<CustomParagraph style={{ textAlign: 'center', fontWeight: 'bold' }}>
					§ 3<br />
					Bezpieczeństwo przetwarzanych danych osobowych
				</CustomParagraph>

				<CustomParagraph>
					T-Create przykłada wagę do bezpieczeństwa przetwarzanych danych osobowych chroniąc je przed dostępem osób nieuprawnionych oraz skutkami nieuprawnionego dostępu przestrzegając następujących
					zasad: <br />
					<br />
					<CustomList style={{ listStyleType: 'lower-alpha' }}>
						<li>
							z zastrzeżeniem § 4 dostęp do danych osobowych przetwarzanych przez T-Create może uzyskać jedynie osoba, która będąc pracownikiem lub podwykonawcą Spółki zaakceptowała niniejszą politykę
							prywatności i zobowiązała się przestrzegać zawartych w niej zasad;
						</li>
						<li>jeśli dane osobowe przetwarzane są w postaci cyfrowych zbiorów dostęp do takich zasobów chroniony jest hasłem udostępnianym wyłącznie osobom wskazany w punkcie a.;</li>
						<li>
							jeśli dane osobowe przetwarzane są w postaci wydrukowanych dokumentów dostęp do nich zabezpieczony jest poprzez umieszczenie w zamykanym na klucz pomieszczeniu, w siedzibie Spółki
							objętej systemem monitoringu i ochrony;
						</li>
						<li>
							w każdym przypadku gdy Spółka poweźmie informację o zdarzeniu w efekcie którego dostęp do danych osobowych uzyskała nieuprawniona do tego osoba lub podmiot, T-Create w ciągu 72 godzin
							poinformuje o tym fakcie Prezesa Urzędu Ochrony Danych Osobowych i osoby, których dane były przedmiotem zdarzenia oraz podejmie wszelkie przewidziane prawem i dostępne w ramach
							możliwości Spółki działania celem minimalizacji szkód jakie mogłyby w rezultacie zdarzenia ponieść te osoby;
						</li>
						<li>przypadki naruszeń bezpieczeństwa danych osobowych będą każdorazowo wprowadzane do rejestru czynności RODO </li>
					</CustomList>
				</CustomParagraph>

				<CustomParagraph style={{ textAlign: 'center', fontWeight: 'bold' }}>
					§ 4<br />
					Udostępnianie danych osobowych
				</CustomParagraph>

				<CustomParagraph>
					Przetwarzane przez T-Create dane osobowe mogą być w uzasadnionych prawem i prawnie uzasadnionym interesem Spółki przypadkach przekazywane innym podmiotom i osobom. Dotyczy to w
					szczególności:
					<br />
					<br />
					<CustomList style={{ listStyleType: 'lower-alpha' }}>
						<li>sytuacji gdy obowiązek udostępnienia danych osobowych wynika z przepisów prawa;</li>
						<li>przekazania danych do kancelarii prawnej, biura rachunkowego, banków i innych podmiotów obsługujących płatności, firm windykacyjnych;</li>
						<li>przekazania danych do firmy kurierskiej lub operatora pocztowego w zakresie wymaganym do skutecznej realizacji przesyłki paczki lub listu;</li>
						<li>
							przekazania danych w związku ze świadczeniem na rzecz spółki specjalistycznych usług informatycznych do wyspecjalizowanych firm - operatorów serwerów baz danych, firm analizujących i
							monitorujących zachowanie użytkowników serwisów internetowych i aplikacji, firm udostępniających rozwiązania archiwizacji i udostępniania danych w chmurze.
						</li>
					</CustomList>
					<br />
					<br />
					Przetwarzane przez T-Create dane osobowe mogą być udostępniane do krajów trzecich wyłącznie w przypadkach gdy podmiot lub osoba wymienionych w punktach powyżej świadczyć będzie swoje usługi
					z wykorzystaniem zasobów zlokalizowanych poza Unią Europejską, na którą to okoliczność Spółka nie ma wpływu. <br />
					T-Create z uwagą dobiera profesjonalnych partnerów biznesowych do realizacji wyżej wymienionych usług mając na uwadze także aspekt bezpieczeństwa powierzonych danych osobowych.
				</CustomParagraph>

				<CustomParagraph style={{ textAlign: 'center', fontWeight: 'bold' }}>
					§ 5<br />
					Sposoby realizacji prawa dostępu do danych osób, których dane dotyczą
				</CustomParagraph>
				<CustomParagraph>
					T-Create udostępnia możliwość realizacji niżej wymienionych praw osób fizycznych, których dane przetwarza: <br />
					<br />
					<CustomList>
						<li>prawo do informacji</li>
						<li>prawo do poprawiania lub usuwania danych,</li>
						<li>prawo do ograniczenia przetwarzania danych,</li>
						<li>prawo do przenoszenia danych.</li>
					</CustomList>
				</CustomParagraph>
				<CustomParagraph>
					poprzez uruchomienie formularza RODO na stronie internetowej www.tcreate.pl/rodo/ poprzez który osoba fizyczna, której dane podlegają przetwarzaniu w T-Create ma możliwość realizacji każdego
					z tych praw. Zależnie od zakresu przetwarzanych danych i opcji formularza z jakich skorzysta zainteresowana osoba, której dane przetwarzamy, działanie formularza RODO polegające na obsłudze
					takiego zapytania bądź żądania może być zautomatyzowane lub realizowane przez upoważnionego pracownika T-Create.
				</CustomParagraph>
				<CustomParagraph>
					Dodatkowo wybrane serwisy internetowe lub aplikacje udostępniane przez T-Create w związku z realizacją Akcji Promocyjnych mogą posiadać dodatkowe narzędzia informatyczne umożliwiające
					osobom, których dane przetwarzamy realizację przysługujących im praw.
				</CustomParagraph>

				<CustomParagraph style={{ textAlign: 'center', fontWeight: 'bold' }}>
					§ 6<br />
					Informacje dotyczące osób małoletnich
				</CustomParagraph>

				<CustomParagraph>
					T-Create nie przetwarza danych osób małoletnich bez zgody ich opiekunów prawnych. W przypadku powzięcia informacji o takim przypadku dane takie będą usuwane o ile fakt usunięcia nie będzie
					stał w sprzeczności z prawnie uzasadnionym interesem Spółki.
				</CustomParagraph>

				<CustomParagraph style={{ textAlign: 'center', fontWeight: 'bold' }}>
					§ 7<br />
					Postanowienie końcowe
				</CustomParagraph>

				<CustomParagraph>
					<CustomList style={{ listStyleType: 'lower-alpha' }}>
						<li> T-Create zastrzega sobie prawo do przetwarzania danych w sposób zautomatyzowany w tym w formie profilowania.</li>
						<li>
							{' '}
							T-Create zastrzega sobie możliwość wprowadzenia zmian w niniejszej Polityce Prywatności ze względu na możliwe zmiany w zakresie ochrony danych osobowych lub rozwoju funkcjonalności w
							zakresie systemów informatycznych. Aktualizacja Polityki Prywatności każdorazowo będzie wprowadzana poprzez stronę www.tcreate.pl/rodo/ gdzie będzie również udostępniana aktualna wersja
							dokumentu.
						</li>
						<li>
							Każda osoba, która uważa, że T-Create przetwarzając dane osobowe narusza przepisy o ochronie danych osobowych, ma prawo wniesienia skargi do organu nadzorczego. Jednocześnie jednak
							T-Create w trosce o bezpieczeństwo powierzonych danych udostępnia pod adresem www.tcreate.pl/rodo/ formularz zgłoszeń takich przypadków, które będą dokładnie i wnikliwie analizowane w
							ramach wewnętrznej kontroli.
						</li>
						<li>
							Wszelkie pytania, sugestie lub uwagi dotyczące realizacji Polityki Prywatności i przepisów ochrony danych osobowych przez T-Create mogą być zgłaszane za pośrednictwem formularza kontaktu
							strony www.tcreate.pl/rodo/
						</li>
						<li>Niniejsza polityka prywatności wchodzi w życie z dniem 01.01.2021 i opatrzona jest sygnaturą wersji: 01/2021</li>
					</CustomList>
				</CustomParagraph>
			</PrivacyPolicyContainer>
		</SiteContainer>
	);
};

export default PrivacyPolicy;
